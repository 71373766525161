import { TDiscipline } from 'src/design-system'
import { PositionsPageProps } from './positions-page'

export class PositionsPageVm {
  constructor(
    private disciplines: TDiscipline[],
    private permissions: PositionsPageProps['permissions']
  ) {}

  get showEmptyState(): boolean {
    if (this.disciplines.length === 0) return true
    if (!this.hasPositions() && !this.permissions?.allowEditDiscipline)
      return true

    return false
  }

  private hasPositions(): boolean {
    return this.disciplines.some(
      (discipline) => discipline.positions && discipline.positions.length > 0
    )
  }
}
