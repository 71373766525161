import { TDiscipline } from 'src/design-system'
import { InternalApiClient } from './internal-api-client'
import { errorToast } from '../utils/error-toast'

export class DisciplinesService {
  constructor(private client: InternalApiClient) {}

  async destroy(props: { frameworkId: number; discipline: TDiscipline }) {
    const { discipline, frameworkId } = props

    await this.client.put(`/frameworks/${frameworkId}/remove_discipline`, {
      discipline_id: discipline.id,
    })
  }

  async changeOrder(props: { disciplines: TDiscipline[] }) {
    const { disciplines } = props

    try {
      const disciplineIds = disciplines.map((d) => d.id)

      let url = '/framework_disciplines/sort?'
      url += disciplineIds.map((id) => `discipline[]=${id}`).join('&')

      await this.client.patch(url, {})
      return true
    } catch (e) {
      errorToast()
      return false
    }
  }
}
