import { useModalContext } from 'src/design-system'
import { MovePositionDialogProps } from 'components/move-position-dialog'
import { MOVE_POSITION_DIALOG_ID } from 'components/move-position-dialog/utils'
import { MoveTrackDialogProps } from 'components/move-track-dialog'
import { MOVE_TRACK_DIALOG_ID } from 'components/move-track-dialog/utils'
import {
  POSITION_MODAL_ID,
  PositionModalProps,
} from 'components/position-modal/utils'
import * as React from 'react'
import { store } from 'store/index'
import { DisciplinesService } from '../../services/disciplines-service'
import { PositionsService } from '../../services/positions-service'
import { errorToast } from '../../utils/error-toast'
import { openModal as openRailsModal } from '../../utils/open-modal'
import { promiseToast } from '../../utils/promise-toast'
import { successToast } from '../../utils/success-toast'
import { useApiClient } from '../../utils/use-api-client'
import { EmptyPositionsPage } from '../empty-positions-page'
import { PositionsPage, PositionsPageProps } from './positions-page'
import { PositionsPageVm } from './positions-page-vm'

export type RailsPositionsPageProps = PositionsPageProps & {
  orgId: number
  frameworkId: number
  isDraft: boolean
}

export const RailsPositionsPage: React.VFC<RailsPositionsPageProps> = (
  initialProps
) => {
  const [props, setProps] =
    React.useState<RailsPositionsPageProps>(initialProps)
  const { orgId, frameworkId, isDraft, ...restProps } = props

  const client = useApiClient()
  const { openModal } = useModalContext()

  const disciplinesService = React.useMemo(
    () => new DisciplinesService(client),
    [client]
  )
  const positionsService = React.useMemo(() => new PositionsService(), [])

  const duplicateDiscipline = async (disciplineId: string) => {
    const result = await store.disciplines.create({
      clonedFrom: disciplineId,
    })

    if (result.success) hydrateProps()
    return result
  }

  const onClickPosition = (positionId: string, disciplineId?: string) => {
    openModal<PositionModalProps>(POSITION_MODAL_ID, {
      positionId: positionId,
      disciplineId: disciplineId,
      source: 'positions_page',
    })
  }

  const baseProps: Partial<PositionsPageProps> = {
    eventHandlers: {
      onAddDiscipline: () => {
        openRailsModal(`/add_disciplines_modal?framework_id=${frameworkId}`)
      },
      onClickTeamMember: (user) => {
        window.location.href = `/users/${user?.slug}`
      },
      onClickPosition: (position, discipline) => {
        if (!position?.id) return

        onClickPosition(position.id.toString(), discipline?.id?.toString())
      },
      onDuplicateDiscipline: async (discipline) => {
        if (discipline?.id) {
          promiseToast(
            'positions-page-duplicate-discipline',
            duplicateDiscipline(discipline.id.toString()),
            'Track duplicated',
            undefined,
            'Duplicating track..'
          )
        }
      },
      onDuplicatePosition: async (position) => {
        if (position?.id) {
          const result = await store.positions.create({
            origin: position.id.toString(),
          })

          if (result.success) {
            successToast('Position duplicated')
            hydrateProps()
          } else {
            errorToast()
          }
        }
      },
      onEditDiscipline: async (discipline, newTitle) => {
        if (newTitle.length === 0) return 'Please provide a valid title.'

        try {
          const url = `/frameworks/${frameworkId}/update_discipline`
          await client.post(url, {
            discipline: {
              name: newTitle,
              id: discipline.id,
            },
          })

          hydrateProps()
        } catch (e) {
          return (e as Error).message
        }
      },
      onDeleteDiscipline: async (discipline) => {
        await disciplinesService.destroy({ discipline, frameworkId })
        hydrateProps()
      },
      onDeletePosition: async (position) => {
        const positionId = position?.id?.toString()
        if (!positionId) return

        await store.positions.fetchOne(positionId)
        const storePosition = store.positions.byId(positionId)
        if (!storePosition) return

        const result = await storePosition.destroy()

        if (result.success) {
          successToast('Position deleted')
          hydrateProps()
        } else {
          errorToast()
        }
      },
      onEditPosition: (position, discipline) => {
        if (!position?.id) return

        onClickPosition(position.id.toString(), discipline?.id?.toString())
      },
      onAssignUserToPosition: (position) => {
        openRailsModal(
          `/assign_user_to_position_modal?limit=2&org_id=${orgId}&position_id=${position?.id}&origin=positions_page`
        )
      },
      onCreatePosition: async (discipline, seniorityLevel, name) => {
        // prevent an API call on an empty string
        if (name === '') return

        if (name && name.length > 0) {
          await positionsService.create({
            name,
            frameworkId,
            disciplineId: discipline.id,
            seniorityLevel,
          })

          hydrateProps()
        } else {
          openRailsModal(
            `/add_position_modal?discipline=${discipline.id}&framework_id=${frameworkId}&seniority_level=${seniorityLevel}`
          )
        }
      },
      onAddSkills: () => {
        location.assign(`${location.pathname}/skills`)
      },
      onMoveDiscipline: async (discipline) => {
        const disciplineId = discipline?.id?.toString()
        if (!disciplineId) return

        const storeDiscipline = store.disciplines.byId(disciplineId)
        if (!storeDiscipline) return

        openModal<MoveTrackDialogProps>(MOVE_TRACK_DIALOG_ID, {
          discipline: storeDiscipline,
        })
      },
      onMovePosition: async (position) => {
        if (position?.id) {
          await store.positions.fetchOne(position.id.toString())

          const storePosition = store.positions.byId(position.id.toString())
          if (!storePosition) return

          openModal<MovePositionDialogProps>(MOVE_POSITION_DIALOG_ID, {
            position: storePosition,
          })
        }
      },
      onReorderDisciplines: async (disciplines) => {
        return await disciplinesService.changeOrder({ disciplines })
      },
    },
  }

  const hydrateProps = React.useCallback(async () => {
    const newProps = await client.get<RailsPositionsPageProps>(location.href)
    setProps(newProps)
  }, [client])

  React.useEffect(() => {
    document.addEventListener('hydrate', hydrateProps)

    return () => {
      document.removeEventListener('hydrate', hydrateProps)
    }
  }, [hydrateProps])

  const { disciplines, permissions } = props

  const positionsPageVm = React.useMemo(() => {
    return new PositionsPageVm(disciplines || [], permissions)
  }, [disciplines, permissions])

  if (positionsPageVm.showEmptyState)
    return (
      <EmptyPositionsPage
        allowEditDiscipline={Boolean(props.permissions?.allowEditDiscipline)}
        frameworkId={frameworkId}
        isDraft={isDraft}
      />
    )

  return <PositionsPage {...baseProps} {...restProps} />
}
