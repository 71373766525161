import * as React from 'react'
import { Button } from 'src/design-system'
import emptyPositions from '../../../images/empty-positions.svg'
import emptyDisciplines from '../../../images/empty-disciplines.svg'
import { openModal } from '../../utils/open-modal'

export type EmptyPositionsPageProps = {
  frameworkId: number
  isDraft?: boolean
  allowEditDiscipline: boolean
}

export const EmptyPositionsPage: React.VFC<EmptyPositionsPageProps> = (
  props
) => {
  const { allowEditDiscipline, isDraft, frameworkId } = props

  const onAddDiscipline = () => {
    openModal(`/add_disciplines_modal?framework_id=${frameworkId}`)
  }

  if (allowEditDiscipline) {
    return <WithEditContent onAddDiscipline={onAddDiscipline} />
  }

  if (isDraft) return <DraftWithoutEditContent />

  return <PublishedWithoutEditContent />
}

const WithEditContent: React.VFC<{ onAddDiscipline: () => void }> = ({
  onAddDiscipline,
}) => (
  <div className="flex flex-col flex-1 items-center justify-center text-center">
    <Image src={emptyDisciplines} />
    <Heading>No tracks yet</Heading>
    <Content>Let&apos;s add a track to your team.</Content>
    <Button className="my-4 mx-auto" size="lg" onClick={onAddDiscipline}>
      <span aria-hidden={true}>+ </span>Add a track
    </Button>
  </div>
)

const DraftWithoutEditContent: React.VFC = () => (
  <div className="flex flex-col flex-1 items-center justify-center text-center">
    <Image src={emptyPositions} />
    <Heading>This team is in draft</Heading>
    <Content>
      This team is still being worked on. Once it&apos;s published you&apos;ll
      be able to view it.
    </Content>
  </div>
)

const PublishedWithoutEditContent: React.VFC = () => (
  <div className="flex flex-col flex-1 items-center justify-center text-center">
    <Image src={emptyPositions} />
    <Heading>No positions yet</Heading>
    <Content>Get an admin to create some!</Content>
  </div>
)

const Image = ({ src }: { src: string }) => (
  <img className="pb-4 mx-auto h-[200px] w-auto" src={src} alt="" />
)

const Heading = ({ children }: { children: string }) => (
  <h2 className="text-2xl font-bold">{children}</h2>
)

const Content = ({ children }: { children: string }) => (
  <p className="mb-0 text-sm">{children}</p>
)
